/*******************************************************************************
                              CUSTOM SCROLLBAR
                    (not supported in Firefox or IE/Edge)
*******************************************************************************/
/* width */
::-webkit-scrollbar {
    width: 5px;
}
/* Track */
::-webkit-scrollbar-track {
    border-radius: 10px;
}
/* Handle */
::-webkit-scrollbar-thumb {
    background: #009AC6;
    border-radius: 10px;
}
/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: #009AC6;
}
