button {
  cursor: pointer;
}
.pinploy_button {
  width: 300px;
  height: 40px;
  border-radius: 3px;
  background-color: #009ac6 !important;
  color: white;
  font-size: 14px;

  &:hover {
    box-shadow: 0 1px 4px 1px rgba(0, 0, 0, 0.2);
  }
  &.small_button {
    width: 150px;
  }
  &.red_button {
    background-color: #f82e42 !important;
  }
  &.green_button {
    background-color: #00a651 !important;
  }
  &.disabled {
    opacity: 0.5;
    cursor: not-allowed;
    &:hover {
      box-shadow: none;
    }
  }
}
